<template>
  <div class="nursery" v-if="hasPerm('nursery.view_nurseryinscription')">
    <page-header title="Fréquentation Crèche" icon="fa fa-baby-carriage" :links="getLinks()"></page-header>
    <div>
      <b-row>
        <b-col cols="6">
          <date-frame-selector @change="onDateRangeChanged">
          </date-frame-selector>
        </b-col>
        <b-col></b-col>
        <b-col>
          <b-form-group label-for="showDetail" label="Affichage">
            <b-form-checkbox id="showDetail" v-model="showDetail">
              Détail
            </b-form-checkbox>
          </b-form-group>
        </b-col>
      </b-row>
      <div ref="printMe" style="margin-top: 10px;">
        <loading-gif :loading-name="loadingName"></loading-gif>
        <div ref="excelTable" v-if="!isLoading(loadingName)">
          <table class="table table-striped small">
            <tr>
              <th v-if="showDetail">Date de garde</th>
              <th v-if="showDetail">H. arrivée réelle</th>
              <th v-if="showDetail">H. départ réelle</th>
              <th colspan="3" v-if="!showDetail"></th>
              <th>Durée réalisée</th>
              <th>Durée facturée</th>
              <th>Absent</th>
              <th>Taux de facturation</th>
            </tr>
            <nursery-child-attendance
              v-for="child in children"
              :key="child.id"
              :show-detail="showDetail"
              :child="child"
              :inscriptions="getChildInscriptions(child)"
            >
            </nursery-child-attendance>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapActions, mapMutations } from 'vuex'
import LoadingGif from '@/components/Controls/LoadingGif'
import PageHeader from '@/components/Layout/PageHeader'
import NurseryChildAttendance from '@/components/Nursery/NurseryChildAttendance.vue'
import { BackendMixin } from '@/mixins/backend'
import { makeNurseryInscription } from '@/types/nursery'
import { BackendApi, openDocument } from '@/utils/http'
import { compareStrings } from '@/utils/sorting'
import { distinct } from '@/utils/arrays'
import DateFrameSelector from '@/components/DateRange/DateFrameSelector.vue'

export default {
  name: 'nursery-attendance',
  mixins: [BackendMixin],
  components: {
    NurseryChildAttendance,
    DateFrameSelector,
    LoadingGif,
    PageHeader,
  },
  props: {
  },
  data() {
    return {
      loadingName: 'nursery-attendance',
      inscriptions: [],
      startDate: null,
      endDate: null,
      showDetail: false,
    }
  },
  watch: {
  },
  computed: {
    children() {
      return distinct(this.inscriptions.map(elt => elt.individual)).sort(
        (elt1, elt2) => compareStrings(elt1.lastAndFirstName(), elt2.lastAndFirstName())
      )
    },
  },
  mounted() {
  },
  methods: {
    ...mapActions(['addError', 'addSuccess']),
    ...mapMutations(['startLoading', 'endLoading']),
    onDateRangeChanged(event) {
      this.startDate = event.startDate
      this.endDate = event.endDate
      this.loadInscriptions()
    },
    async loadInscriptions() {
      if (this.startDate && this.endDate) {
        this.startLoading(this.loadingName)
        let url = '/nursery/api/nursery-inscriptions/?'
        url += '&start_date=' + this.startDate + '&end_date=' + this.endDate
        const backendApi = new BackendApi('get', url)
        try {
          const resp = await backendApi.callApi()
          this.inscriptions = resp.data.map(elt => makeNurseryInscription(elt))
        } catch (err) {
          await this.addError(this.getErrorText(err))
        }
        this.endLoading(this.loadingName)
      }
    },
    getLinks() {
      return [
        {
          id: 1,
          label: 'Pdf',
          callback: this.printMe,
          icon: 'fa fa-file-pdf',
          cssClass: this.isLoading(this.loadingName) ? 'btn-secondary disabled' : 'btn-secondary',
        },
        {
          id: 2,
          label: 'Excel',
          callback: this.excelMe,
          icon: 'fa fa-file-excel',
          cssClass: this.isLoading(this.loadingName) ? 'btn-secondary disabled' : 'btn-secondary',
        }
      ]
    },
    getChildInscriptions(child) {
      return this.inscriptions.filter(ins => ins.individual.id === child.id)
    },
    async printMe() {
      const docUrl = '/documents/standard/<key>/pdf/?landscape=1'
      const docSlug = 'contrats-creche'
      const docContent = this.$refs.printMe.innerHTML.toString()
      try {
        await openDocument(docUrl, docSlug, docContent)
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    async excelMe() {
      const docUrl = '/documents/table-to-excel/<key>/'
      const docSlug = 'contrats-creche'
      const docContent = this.$refs.excelTable.innerHTML.toString()
      try {
        await openDocument(docUrl, docSlug, docContent)
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
  },
}
</script>

<style lang="less" scoped>
</style>
